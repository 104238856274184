<script>
import { ref, computed, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

export default {
  props: {
    template: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['sendMessage', 'resetTemplate'],
  methods: {
    getHeaderContent(header) {
      if (!header) return '';
      if (header.text) return header.text;

      switch (header.format) {
        case 'IMAGE':
          return 'URL da Imagem: {{1}}';
        case 'DOCUMENT':
          return 'URL do Documento: {{1}}';
        case 'VIDEO':
          return 'URL do Vídeo: {{1}}';
        case 'LOCATION':
          return 'Latitude: {{1}}, Longitude: {{2}}, Nome: {{3}}, Endereço: {{4}}';
        default:
          return '';
      }
    },
  },
  setup(props, { emit }) {
    const processVariable = str => {
      return str.replace(/{{|}}/g, '');
    };

    const allKeysRequired = value => {
      const keys = Object.keys(value);
      return keys.every(key => value[key]);
    };

    const processedParams = ref({});

    const getComponentTexts = () => {
      const texts = [];

      const components = props.template.components;

      const header = components.find(c => c.type === 'HEADER');
      if (header && header.text) texts.push(header.text);

      const body = components.find(c => c.type === 'BODY');
      if (body && body.text) texts.push(body.text);

      const footer = components.find(c => c.type === 'FOOTER');
      if (footer && footer.text) texts.push(footer.text);

      const buttons = components.find(c => c.type === 'BUTTONS');
      if (buttons && buttons.buttons) {
        buttons.buttons.forEach(button => {
          if (button.url && button.type != "MPM") {
            texts.push(button.url);
          }else if(button.type == "MPM"){
            texts.push(button.text);
          }else{
            texts.push(button.url);
          }
        });
      }
      return texts;
    };

    const templateString = computed(() => {
      return getComponentTexts();
    });

    const variables = computed(() => {
      const combinedText = templateString.value.join('\n\n');
      return combinedText.match(/{{([^}]+)}}/g);
    });

    const processedString = computed(() => {
      let variableIndex = 1;
      const texts = getComponentTexts();
      const combinedText = texts.join(' ');
      return combinedText.replace(/{{([^}]+)}}/g, (match, variable) => {
        const variableKey = `{{${variableIndex}}}`;
        variableIndex++;
        return processedParams.value[variableKey] || `{{${variable}}}`;
      });
    });

    const v$ = useVuelidate(
      {
        processedParams: {
          requiredIfKeysPresent: requiredIf(variables),
          allKeysRequired,
        },
      },
      { processedParams }
    );

    const generateVariables = () => {
      let variableIndex = 1;
      const matchedVariables = templateString.value.join(' ').match(/{{([^}]+)}}/g);

      if (!matchedVariables) return;

      const finalVars = matchedVariables.map(i => processVariable(i));

      processedParams.value = finalVars.reduce((acc, variable) => {
        acc[`{{${variableIndex}}}`] = '';
        variableIndex++;
        return acc;
      }, {});
    };

    const getMessageComponents = () => {
      const components = [];
      const header = props.template.components.find(c => c.type === 'HEADER');
      if (header) {
        components.push({
          type: 'HEADER',
          text: header.text,
          format: header.format
        });
      }
      const body = props.template.components.find(c => c.type === 'BODY');
      if (body) {
        components.push({
          type: 'BODY',
          text: body.text
        });
      }
      const footer = props.template.components.find(c => c.type === 'FOOTER');
      if (footer) {
        components.push({
          type: 'FOOTER',
          text: footer.text
        });
      }
      const buttons = props.template.components.find(c => c.type === 'BUTTONS');
      if (buttons) {
        components.push({
          type: 'BUTTONS',
          buttons: buttons.buttons
        });
      }
      return components;
    };

    const resetTemplate = () => {
      emit('resetTemplate');
    };

    const sendMessage = () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;

      const payload = {
        message: processedString.value,
        templateParams: {
          name: props.template.name,
          category: props.template.category,
          language: props.template.language,
          namespace: props.template.namespace,
          processed_params: processedParams.value,
          components: getMessageComponents(),
          template_data: props.template || null
        },
      };
      emit('sendMessage', payload);
    };

    onMounted(generateVariables);

    return {
      processedParams,
      variables,
      templateString,
      processedString,
      v$,
      resetTemplate,
      sendMessage,
    };
  },
};
</script>

<template>
  <div class="w-full">
    <div class="template-preview">
      <div v-if="template.components.find(c => c.type === 'HEADER')" class="preview-section header">
        <span class="section-label">Conteúdo do Header</span>
        <div class="preview-content">
          {{
            getHeaderContent(template.components.find(c => c.type === 'HEADER'))
          }}
        </div>
      </div>
      <div v-if="template.components.find(c => c.type === 'BODY')" class="preview-section body">
        <span class="section-label">Conteúdo do Body</span>
        <div class="preview-content">
          {{ template.components.find(c => c.type === 'BODY').text }}
        </div>
      </div>
      <div v-if="template.components.find(c => c.type === 'FOOTER')" class="preview-section footer">
        <span class="section-label">Conteúdo do Footer</span>
        <div class="preview-content">
          {{ template.components.find(c => c.type === 'FOOTER').text }}
        </div>
      </div>
      <div v-if="template.components.find(c => c.type === 'BUTTONS')" class="preview-section buttons">
        <span class="section-label">Botões</span>
        <div class="buttons-container">
          <div
            v-for="(button, index) in template.components.find(c => c.type === 'BUTTONS').buttons"
            :key="index"
            class="button-preview"
          >
            {{ button.text }}<br/>{{ `URL: ${button.url}` }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="variables" class="template__variables-container">
      <p class="variables-label">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.VARIABLES_LABEL') }}
      </p>
      <div
        v-for="(variable, key) in processedParams"
        :key="key"
        class="template__variable-item"
      >
        <span class="variable-label">
          {{ key }}
        </span>
        <woot-input
          v-model="processedParams[key]"
          type="text"
          class="variable-input"
          :styles="{ marginBottom: 0 }"
        />
      </div>
      <p v-if="v$.$dirty && v$.$invalid" class="error">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.FORM_ERROR_MESSAGE') }}
      </p>
    </div>
    <footer>
      <woot-button variant="smooth" @click="resetTemplate">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.GO_BACK_LABEL') }}
      </woot-button>
      <woot-button type="button" @click="sendMessage">
        {{ $t('WHATSAPP_TEMPLATES.PARSER.SEND_MESSAGE_LABEL') }}
      </woot-button>
    </footer>
  </div>
</template>

<style scoped lang="scss">
.template-preview {
  @apply bg-slate-25 dark:bg-slate-900 rounded-lg p-4 mb-4;

  .preview-section {
    @apply mb-4 last:mb-0;
  }

  .section-label {
    @apply text-xs font-medium text-slate-600 dark:text-slate-300 block mb-1;
  }

  .preview-content {
    @apply bg-white dark:bg-slate-800 p-3 rounded-md text-sm;
  }

  .buttons-container {
    @apply space-y-2;
  }

  .button-preview {
    @apply bg-woot-50 dark:bg-slate-700 p-2 rounded-md text-center text-sm;
  }
}

.template__variables-container {
  @apply p-2.5;
}

.variables-label {
  @apply text-sm font-semibold mb-2.5;
}

.template__variable-item {
  @apply items-center flex mb-2.5;

  .variable-input {
    @apply flex-1 text-sm ml-2.5;
  }

  .variable-label {
    @apply bg-slate-75 dark:bg-slate-700 text-slate-700 dark:text-slate-100 inline-block rounded-md text-xs py-2.5 px-6;
  }
}

footer {
  @apply flex justify-end;

  button {
    @apply ml-2.5;
  }
}

.error {
  @apply bg-red-100 dark:bg-red-100 rounded-md text-red-800 dark:text-red-800 p-2.5 text-center;
}
</style>
