<script setup>
import { computed, ref, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

import Input from 'dashboard/components-next/input/Input.vue';
import Button from 'dashboard/components-next/button/Button.vue';

const props = defineProps({
  template: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['sendMessage', 'back']);

const { t } = useI18n();

const processedParams = ref({});

const templateName = computed(() => {
  return props.template?.name || '';
});

const getComponentTexts = () => {
  const texts = [];
  const components = props.template.components;

  const header = components.find(c => c.type === 'HEADER');
  if (header && header.text) texts.push(header.text);

  const body = components.find(c => c.type === 'BODY');
  if (body && body.text) texts.push(body.text);

  const footer = components.find(c => c.type === 'FOOTER');
  if (footer && footer.text) texts.push(footer.text);

  const buttons = components.find(c => c.type === 'BUTTONS');
  if (buttons && buttons.buttons) {
    buttons.buttons.forEach(button => {
      if (button.url && button.type !== "MPM") {
        texts.push(button.url);
      } else if (button.type === "MPM") {
        texts.push(button.text);
      } else {
        texts.push(button.url);
      }
    });
  }
  return texts;
};

const templateString = computed(() => {
  return getComponentTexts().join('\n\n');
});

const processVariable = str => {
  return str.replace(/{{|}}/g, '');
};

const processedString = computed(() => {
  return templateString.value.replace(/{{([^}]+)}}/g, (match, variable) => {
    const variableKey = processVariable(variable);
    return processedParams.value[variableKey] || `{{${variable}}}`;
  });
});

const rules = computed(() => {
  const paramRules = {};
  Object.keys(processedParams.value).forEach(key => {
    paramRules[key] = { required: requiredIf(true) };
  });
  return {
    processedParams: paramRules,
  };
});

const v$ = useVuelidate(rules, { processedParams });

const generateVariables = () => {
  const matchedVariables = templateString.value.match(/{{([^}]+)}}/g);
  if (!matchedVariables) return;

  const finalVars = matchedVariables.map(i => processVariable(i));
  processedParams.value = finalVars.reduce((acc, variable) => {
    acc[variable] = '';
    return acc;
  }, {});
};

const sendMessage = async () => {
  const isValid = await v$.value.$validate();
  if (!isValid) return;

  const components = props.template.components.map(component => {
    if (component.type === 'BUTTONS') {
      return {
        type: component.type,
        buttons: component.buttons,
      };
    }
    return {
      type: component.type,
      text: component.text || '',
      format: component.format || '',
    };
  });

  const payload = {
    message: processedString.value,
    templateParams: {
      name: props.template.name,
      category: props.template.category,
      language: props.template.language,
      namespace: props.template.namespace,
      processed_params: processedParams.value,
      components,
      template_data: props.template || null      
    },
  };
  emit('sendMessage', payload);
};

onMounted(() => {
  generateVariables();
});
</script>

<template>
  <div
    class="absolute top-full mt-1.5 max-h-[500px] overflow-y-auto left-0 flex flex-col gap-4 px-4 pt-6 pb-5 items-start w-[460px] h-auto bg-n-solid-2 border border-n-strong shadow-sm rounded-lg"
  >
    <span class="text-sm text-n-slate-12">
      {{
        t(
          'COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.TEMPLATE_PARSER.TEMPLATE_NAME',
          { templateName: templateName }
        )
      }}
    </span>
    <div v-if="template.components && template.components.find(c => c.type === 'HEADER')" class="preview-section header">
      <span class="section-label">Conteúdo do Header</span>
      <div class="preview-content">
        {{ template.components.find(c => c.type === 'HEADER')?.text || 'Nenhum conteúdo no header.' }}
      </div>
    </div>
    <div v-if="template.components.find(c => c.type === 'BODY')" class="preview-section body">
      <span class="section-label">Conteúdo do Body</span>
      <div class="preview-content">
        {{ template.components.find(c => c.type === 'BODY').text || 'Nenhum conteúdo no body.' }}
      </div>
    </div>
    <div v-if="template.components.find(c => c.type === 'FOOTER')" class="preview-section footer">
      <span class="section-label">Conteúdo do Footer</span>
      <div class="preview-content">
        {{ template.components.find(c => c.type === 'FOOTER').text || 'Nenhum conteúdo no footer.' }}
      </div>
    </div>
    <div v-if="template.components.find(c => c.type === 'BUTTONS')" class="preview-section buttons">
      <span class="section-label">Botões</span>
      <div class="buttons-container">
        <div
          v-for="(button, index) in template.components.find(c => c.type === 'BUTTONS').buttons"
          :key="index"
          class="button-preview"
        >
          {{ button.text }}<br/>{{ `URL: ${button.url || 'Nenhuma URL'}` }}
        </div>
      </div>
    </div>

    <span
      v-if="Object.keys(processedParams).length"
      class="text-sm font-medium text-n-slate-12"
    >
      {{
        t(
          'COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.TEMPLATE_PARSER.VARIABLES'
        )
      }}
    </span>

    <div
      v-for="(variable, key) in processedParams"
      :key="key"
      class="flex items-center w-full gap-2"
    >
      <span
        class="flex items-center h-8 text-sm min-w-6 ltr:text-left rtl:text-right text-n-slate-10"
      >
        {{ key }}
      </span>
      <Input
        v-model="processedParams[key]"
        custom-input-class="!h-8 w-full !bg-transparent"
        class="w-full"
      />
    </div>

    <div class="flex items-end justify-between w-full gap-3 h-14">
      <Button
        :label="
          t(
            'COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.TEMPLATE_PARSER.BACK'
          )
        "
        color="slate"
        variant="faded"
        class="w-full font-medium"
        @click="emit('back')"
      />
      <Button
        :label="
          t(
            'COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.TEMPLATE_PARSER.SEND_MESSAGE'
          )
        "
        class="w-full font-medium"
        @click="sendMessage"
      />
    </div>
  </div>
</template>
