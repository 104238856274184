<script>
export default {
  props: {
    inboxId: {
      type: Number,
      default: undefined,
    },
    webhookUrl: {
      type: Text,
      default: undefined,
    }
  },
  emits: ['onSelect'],
  data() {
    return {
      query: '',
      templates: [],
    };
  },
  computed: {
    whatsAppTemplateMessages() {
      return this.$store.getters['inboxes/getWhatsAppTemplates'](this.inboxId).filter(
        template => template.status.toLowerCase() === 'approved'
      );
    },
    filteredTemplateMessages() {
      if (this.webhookUrl && this.webhookUrl.endsWith('/webhook/chatwoot')) {
        // GupShup
        const myTeams = this.$store.getters['teams/getMyTeams'];
        const detailsCurrentUser = this.$store.getters['getCurrentUser'];
        return this.templates
          .filter(template => template.status.toLowerCase() === 'approved')
          .filter(template => {
            // Permitir todos os templates se o usuário for administrador
            if (detailsCurrentUser.role === 'administrator') {
              return true;
            }

            // Filtra para times com id == 21 e vertical "qg" ou "todos"
            const isTeamAllowed =
              myTeams.some(team => team.name === 'qg acelera') &&
              ['qg', 'todos'].includes(template.vertical);

            // Filtra para usuários com role "agent" e vertical "polo" ou "todos"
            const isUserAllowed =
              detailsCurrentUser.role === 'agent' &&
              ['polo', 'todos'].includes(template.vertical) &&
              !myTeams.some(team => team.name === 'qg acelera');

            // Retorna apenas se atender a uma das condições
            return isTeamAllowed || isUserAllowed;
          })
          .filter(template =>
            template.elementName.toLowerCase().includes(this.query.toLowerCase())
          );
      } else {
        return this.whatsAppTemplateMessages.filter(template =>
          template.name.toLowerCase().includes(this.query.toLowerCase())
        );
      }
    },
  },
  methods: {
    getTemplateContent(template, index) {
      const content = [];
      let variableCounter = 1;

      const getHeaderContent = (header) => {
        if (!header) return '';
        if (header.text) return header.text;

        switch (header.format) {
          case 'IMAGE':
            return 'URL da Imagem: {{1}}';
          case 'DOCUMENT':
            return 'URL do Documento: {{1}}';
          case 'VIDEO':
            return 'URL do Vídeo: {{1}}';
          case 'LOCATION':
            return 'Latitude: {{1}}, Longitude: {{2}}, Nome: {{3}}, Endereço: {{4}}';
          default:
            return '';
        }
      };

      const replaceVariables = (text, target) => {
        if (text && text.match(/{{([^}]+)}}/g)) {
          const updatedText = text.replace(/\{\{\d+\}\}/g, () => `{{${variableCounter++}}}`);
          if (target) {
            target.text = updatedText;
          }
          return updatedText;
        }
        return text;
      };

      if(this.webhookUrl && this.webhookUrl.endsWith('/webhook/chatwoot')){
        //GupShup
        const containerMeta = JSON.parse(template.containerMeta);
        if (containerMeta) {
          const updatedText = containerMeta.header && containerMeta.header.match(/{{([^}]+)}}/g) ? replaceVariables(containerMeta.header) : (template.templateType == 'IMAGE' || template.templateType == 'DOCUMENT' || template.templateType == 'VIDEO' || template.templateType == 'LOCATION') ? replaceVariables(getHeaderContent({ format: template.templateType })) : containerMeta.header ? containerMeta.header : '';
          if(updatedText){
            content.push({
              type: 'HEADER',
              text: updatedText,
              format: template.templateType,
            });
          }
        }

        if (template.data) {
          const updatedText = template.data && template.data.match(/{{([^}]+)}}/g) ? replaceVariables(template.data) : template.data ? template.data : '';
          if(updatedText){
            content.push({
              type: 'BODY',
              text: updatedText,
            });
          }
        }

        if (containerMeta && containerMeta.footer) {
          const updatedText = containerMeta.footer && containerMeta.footer.match(/{{([^}]+)}}/g) ? replaceVariables(containerMeta.footer) : containerMeta.footer ? containerMeta.footer : '';
          if(updatedText){
            content.push({
              type: 'FOOTER',
              text: updatedText,
            });
          }
        }

        if (containerMeta && containerMeta.buttons) {
          const updatedButtons = containerMeta.buttons.map(button => ({
            text: button.text && button.text.match(/{{([^}]+)}}/g) ? replaceVariables(button.text, button) : button.type == "MPM" ? replaceVariables(`Informar o/os Content ID (Identificação do conteúdo) do/dos produtos separados por vírgula {{1}}`, button) : button.type,
            type: button.type,
            url: button.url ? replaceVariables(button.url, button) : button.type,
            flow_action: button.flow_action || null,
            flow_id: button.flow_id || null,
            navigate_screen: button.navigate_screen || null
          }));
          content.push({
            type: 'BUTTONS',
            buttons: updatedButtons,
          });
        }

        this.templates = this.templates.map(templateItem => {
          if (templateItem.id === template.id) {
            return {
              ...templateItem,
              components: content
            };
          }
          return templateItem;
        });
      }else{
        const headerComponent = template.components.find(component => component.type === 'HEADER');
        if (headerComponent) {
          const updatedText = headerComponent.text
            ? replaceVariables(headerComponent.text, headerComponent)
            : replaceVariables(getHeaderContent(headerComponent), headerComponent);
          content.push({
            type: 'HEADER',
            text: updatedText,
            format: headerComponent.format,
          });
        }

        const bodyComponent = template.components.find(component => component.type === 'BODY');
        if (bodyComponent) {
          const updatedText = bodyComponent.text
            ? replaceVariables(bodyComponent.text, bodyComponent)
            : null;
          content.push({
            type: 'BODY',
            text: updatedText,
          });
        }

        const footerComponent = template.components.find(component => component.type === 'FOOTER');
        if (footerComponent) {
          const updatedText = footerComponent.text
            ? replaceVariables(footerComponent.text, footerComponent)
            : null;
          content.push({
            type: 'FOOTER',
            text: updatedText,
          });
        }

        const buttonComponent = template.components.find(component => component.type === 'BUTTONS');
        if (buttonComponent) {
          const updatedButtons = buttonComponent.buttons.map(button => ({
            text: button.text && button.text.match(/{{([^}]+)}}/g) ? replaceVariables(button.text, button) : button.type == "MPM" ? replaceVariables(`Informar o/os Content ID (Identificação do conteúdo) do/dos produtos separados por vírgula {{1}}`, button) : button.type,
            type: button.type,
            url: button.url ? replaceVariables(button.url, button) : button.type,
            flow_action: button.flow_action || null,
            flow_id: button.flow_id || null,
            navigate_screen: button.navigate_screen || null
          }));
          buttonComponent.buttons = updatedButtons;
          content.push({
            type: 'BUTTONS',
            buttons: updatedButtons,
          });
        }
      }
      return content;
    },
    async fetchTemplates() {
      if (this.webhookUrl && this.webhookUrl.endsWith('/webhook/chatwoot')) {
        try {
          const response = await fetch(
            'https://api-chat.brasilestudo.com.br/templates'
          );
          const data = await response.json();

          // Filtrando os templates com base na propriedade "vertical"
          this.templates = data.filter(
            template => ['qg','polo','todos'].includes(template.vertical)
          );
        } catch (error) {
          console.error('Erro ao buscar templates:', error);
        }
      }
    },
  },
  mounted() {
    this.fetchTemplates();
  },
};
</script>

<template>
  <div class="w-full">
    <div class="gap-1 templates__list-search">
      <fluent-icon icon="search" class="search-icon" size="16" />
      <input
        v-model="query"
        type="search"
        :placeholder="$t('WHATSAPP_TEMPLATES.PICKER.SEARCH_PLACEHOLDER')"
        class="templates__search-input"
      />
    </div>
    <div class="template__list-container">
      <div v-for="(template, i) in filteredTemplateMessages" :key="template.id">
        <button
          class="template__list-item"
          @click="$emit('onSelect', template)"
        >
          <div>
            <div class="flex items-center justify-between mb-2.5">
              <p class="label-title">
                {{ template.name ? template.name : template.elementName ? template.elementName : '' }}
              </p>
              <span class="template-tag">
                {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.LANGUAGE') }}:
                {{ template.language ? template.language : template.languageCode ? template.languageCode : '' }}
              </span>
            </div>
            <div class="template-content">
              <template v-for="(content, index) in getTemplateContent(template, i)" :key="index">
                <div v-if="content.type === 'HEADER'" class="template-header">
                  <p class="strong">Conteúdo do Header</p>
                  <p class="template-text">{{ content.text }}</p>
                  <span class="format-tag">{{ content.format }}</span>
                </div>
                <div v-if="content.type === 'BODY'" class="template-body">
                  <p class="strong">Conteúdo do Body</p>
                  <p class="template-text">{{ content.text }}</p>
                </div>
                <div v-if="content.type === 'FOOTER'" class="template-footer">
                  <p class="strong">Conteúdo do Footer</p>
                  <p class="template-text">{{ content.text }}</p>
                </div>
                <div v-if="content.type === 'BUTTONS'" class="template-buttons">
                  <p class="strong">Botões</p>
                  <div v-for="(button, btnIndex) in content.buttons" :key="btnIndex" class="button-item">
                    <span class="button-text">{{ button.text }}</span>
                    <span class="button-type">{{ button.type }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div class="label-category">
              <p class="strong">
                {{ $t('WHATSAPP_TEMPLATES.PICKER.LABELS.CATEGORY') }}
              </p>
              <p>{{ template.category }}</p>
            </div>
          </div>
        </button>
        <hr v-if="i != filteredTemplateMessages.length - 1" :key="`hr-${i}`" />
      </div>
      <div v-if="!filteredTemplateMessages.length">
        <p>
          {{ $t('WHATSAPP_TEMPLATES.PICKER.NO_TEMPLATES_FOUND') }}
          <strong>{{ query }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.label-category{
  margin-top: 15px;
}
.templates__list-search {
  @apply items-center flex bg-slate-25 dark:bg-slate-900 mb-2.5 py-0 px-2.5 rounded-md border border-solid border-slate-100 dark:border-slate-700;

  .search-icon {
    @apply text-slate-400 dark:text-slate-300;
  }

  .templates__search-input {
    @apply bg-transparent border-0 text-xs h-9 m-0;
  }
}

.template__list-container {
  @apply bg-slate-25 dark:bg-slate-900 rounded-md max-h-[18.75rem] overflow-y-auto p-2.5;

  .template__list-item {
    @apply rounded-lg cursor-pointer block p-2.5 text-left w-full hover:bg-woot-50 dark:hover:bg-slate-800;

    .template-content {
      @apply space-y-4;
    }

    .template-header, .template-body, .template-footer {
      @apply bg-slate-50 dark:bg-slate-800 p-2 rounded;
    }

    .template-buttons {
      @apply mt-2;

      .button-item {
        @apply flex items-center justify-between bg-slate-100 dark:bg-slate-700 p-2 rounded mt-1;
      }

      .button-text {
        @apply font-medium;
      }

      .button-type {
        @apply text-xs bg-slate-200 dark:bg-slate-600 px-2 py-1 rounded;
      }
    }

    .template-tag {
      @apply inline-block px-2 py-1 text-xs leading-none bg-white rounded-sm cursor-default dark:bg-slate-700 text-slate-800 dark:text-slate-100;
    }

    .format-tag {
      @apply text-xs bg-slate-200 dark:bg-slate-600 px-2 py-1 rounded mt-1 inline-block;
    }

    .template-text {
      @apply font-mono text-sm mt-1;
    }
  }
}

.strong {
  @apply text-xs font-semibold;
}

hr {
  @apply border-b border-solid border-slate-100 dark:border-slate-700 my-2.5 mx-auto max-w-[95%];
}
</style>
