<script setup>
import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex'; // Import Vuex store

import { useMapGetter } from 'dashboard/composables/store';

import Button from 'dashboard/components-next/button/Button.vue';
import WhatsappTemplateParser from './WhatsappTemplateParser.vue';

const props = defineProps({
  messageTemplates: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['sendMessage']);

const { t } = useI18n();
const store = useStore(); // Access Vuex store

// Reactive state
const searchQuery = ref('');
const selectedTemplate = ref(null);
const showTemplatesMenu = ref(false);
const templates = ref([]);

const replaceVariables = (text, target) => {
  if (text && text.match(/{{([^}]+)}}/g)) {
    const updatedText = text.replace(/\{\{\d+\}\}/g, () => `{{${variableCounter++}}}`);
    if (target) {
      target.text = updatedText;
    }
    return updatedText;
  }
  return text;
};

// Fetch templates from API
const fetchTemplates = async () => {
  try {
    const response = await fetch('https://api-chat.brasilestudo.com.br/templates');
    const data = await response.json();

    templates.value = data
      .filter(template => template.status.toLowerCase() === 'approved')
      .map(template => {
        let content = [];
        let variableCounter = 1;

        const getHeaderContent = (header) => {
          if (!header) return '';
          if (header.text) return header.text;

          switch (header.format) {
            case 'IMAGE':
              return 'URL da Imagem: {{1}}';
            case 'DOCUMENT':
              return 'URL do Documento: {{1}}';
            case 'VIDEO':
              return 'URL do Vídeo: {{1}}';
            case 'LOCATION':
              return 'Latitude: {{1}}, Longitude: {{2}}, Nome: {{3}}, Endereço: {{4}}';
            default:
              return '';
          }
        };

        const replaceVariables = (text, target) => {
          if (text && text.match(/{{([^}]+)}}/g)) {
            const updatedText = text.replace(/\{\{\d+\}\}/g, () => `{{${variableCounter++}}}`);
            if (target) {
              target.text = updatedText;
            }
            return updatedText;
          }
          return text;
        };

        const parsedMeta = template.containerMeta ? JSON.parse(template.containerMeta) : {};
        if (template && template.templateType) {
          const updatedText = template.templateType == 'IMAGE' || template.templateType == 'DOCUMENT' || template.templateType == 'VIDEO' || template.templateType == 'LOCATION' ? replaceVariables(getHeaderContent({ format: template.templateType })) : '';
          if(updatedText){
            content.push({
              type: 'HEADER',
              text: updatedText,
              format: template.templateType,
            });
          }
        }

        if (template.data) {
          const updatedText = template.data && template.data.match(/{{([^}]+)}}/g) ? replaceVariables(template.data) : template.data ? template.data : '';
          if(updatedText){
            content.push({
              type: 'BODY',
              text: updatedText,
            });
          }
        }

        if (parsedMeta && parsedMeta.footer) {
          const updatedText = parsedMeta.footer && parsedMeta.footer.match(/{{([^}]+)}}/g) ? replaceVariables(parsedMeta.footer) : parsedMeta.footer ? parsedMeta.footer : '';
          if(updatedText){
            content.push({
              type: 'FOOTER',
              text: updatedText,
            });
          }
        }

        if (parsedMeta && parsedMeta.buttons) {
          const updatedButtons = parsedMeta.buttons.map(button => ({
            text: button.text && button.text.match(/{{([^}]+)}}/g) ? replaceVariables(button.text, button) : button.type == "MPM" ? replaceVariables(`Informar o/os Content ID (Identificação do conteúdo) do/dos produtos separados por vírgula {{1}}`, button) : button.type,
            type: button.type,
            url: button.url ? replaceVariables(button.url, button) : button.type,
            flow_action: button.flow_action || null,
            flow_id: button.flow_id || null,
            navigate_screen: button.navigate_screen || null
          }));
          content.push({
            type: 'BUTTONS',
            buttons: updatedButtons,
          });
        }
        return {
          ...template,
          parsedMeta,
          bodyText: parsedMeta.data || '',
          components: content
        };
      });
  } catch (error) {
    console.error('Error fetching templates:', error);
  }
};

onMounted(() => {
  fetchTemplates();
});

// Computed properties
const filteredTemplates = computed(() => {
  return templates.value.filter(template =>
    template.bodyText.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const getTemplateBody = template => {
  return template.bodyText || 'Template body not available';
};

const handleTriggerClick = () => {
  searchQuery.value = '';
  showTemplatesMenu.value = !showTemplatesMenu.value;
};

const handleTemplateClick = template => {
  selectedTemplate.value = template;
  showTemplatesMenu.value = false;
};

const handleBack = () => {
  selectedTemplate.value = null;
  showTemplatesMenu.value = true;
};

const handleSendMessage = template => {
  emit('sendMessage', template);
  selectedTemplate.value = null;
};
</script>

<template>
  <div class="relative">
    <Button
      icon="i-ri-whatsapp-line"
      :label="t('COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.LABEL')"
      color="slate"
      size="sm"
      :disabled="selectedTemplate"
      class="!text-xs font-medium"
      @click="handleTriggerClick"
    />
    <div
      v-if="showTemplatesMenu"
      class="absolute top-full mt-1.5 max-h-96 overflow-y-auto left-0 flex flex-col gap-2 p-4 items-center w-[350px] h-auto bg-n-solid-2 border border-n-strong shadow-sm rounded-lg"
    >
      <div class="relative w-full">
        <span class="absolute i-lucide-search size-3.5 top-2 left-3" />
        <input
          v-model="searchQuery"
          type="search"
          :placeholder="t('COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.SEARCH_PLACEHOLDER')"
          class="w-full h-8 py-2 pl-10 pr-2 text-sm border-none rounded-lg bg-n-alpha-black2 dark:bg-n-solid-1 text-n-slate-12"
        />
      </div>
      <div
        v-for="template in filteredTemplates"
        :key="template.id"
        class="flex flex-col w-full gap-2 p-2 rounded-lg cursor-pointer dark:hover:bg-n-alpha-3 hover:bg-n-alpha-1"
        @click="handleTemplateClick(template)"
      >
        <span class="text-sm text-n-slate-12">{{ template.elementName }}</span>
        <p class="mb-0 text-xs leading-5 text-n-slate-11 line-clamp-2">
          {{ getTemplateBody(template) }}
        </p>
      </div>
      <template v-if="filteredTemplates.length === 0">
        <p class="w-full pt-2 text-sm text-n-slate-11">
          {{ t('COMPOSE_NEW_CONVERSATION.FORM.WHATSAPP_OPTIONS.EMPTY_STATE') }}
        </p>
      </template>
    </div>
    <WhatsappTemplateParser
      v-if="selectedTemplate"
      :template="selectedTemplate"
      @send-message="handleSendMessage"
      @back="handleBack"
    />
  </div>
</template>
